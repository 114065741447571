export const calculatePrecision = function (val) {
  const value = Number(val);
  if (value >= 1000) return 2;
  else if (value >= 10 && value < 1000) return 4;
  else return 9;
};

export const formatNumber = (number, decimals) => {
  return Number(number).toFixed(decimals);
};

export const formatAmount = (val, exchange = null) => {
  const precision =
    exchange == null
      ? calculatePrecision(val)
      : calculatePrecisionByExchange(exchange, val);

  return formatNumber(val, precision);
};

export const calculatePrecisionByExchange = function (exchangeRate, coins) {
  const exchange = exchangeRate.toFixed(12).toString();

  let decimals = 1;
  if (exchange >= 10000) decimals = 12;
  else if (exchange >= 1000 && exchange < 10000) decimals = 11;
  else if (exchange >= 1 && exchange < 1000) decimals = 10;
  else if (exchange >= 0.1 && exchange < 1) decimals = 6;
  else if (exchange >= 0.01 && exchange < 0.1) decimals = 5;
  else if (exchange >= 0.001 && exchange < 0.01) decimals = 4;
  else decimals = 3;

  const coinsString = coins.toFixed(decimals).toString();
  const decimalPos = coinsString.indexOf('.');

  if (decimalPos === -1) return 0;

  // delete the zeros at the end of the string
  const stringWithoutZeros = coinsString.replace(/0+$/, '');

  // count the decimals after the dot
  decimals = stringWithoutZeros.length - decimalPos - 1;

  return decimals;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatBigInteger = (number) => {
  return new Intl.NumberFormat('en-US').format(number);
};

export const removeTrailingZeros = (number) => {
  let strNumber = number.toString();

  strNumber = strNumber.includes('.')
    ? strNumber.replace(/\.?0+$/, '')
    : strNumber;

  return parseFloat(strNumber);
};
